import React from 'react';
import Nav from 'react-bootstrap/Nav';
import { useLocation } from 'react-router-dom';

export default function NavBar() {
  const location = useLocation();

  return (
    <Nav
      className="justify-content-center my-2"
      variant="pills"
      activeKey={location?.pathname}
    >
      {/* <Nav.Item>
        <Nav.Link href="/">Home</Nav.Link>
      </Nav.Item> */}
      <Nav.Item>
        <Nav.Link href="/soluciones-crrt">Soluciones CRRT</Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link href="/pex">PEX</Nav.Link>
      </Nav.Item>
    </Nav>
  );
}
