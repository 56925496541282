import React, { Suspense, lazy } from 'react';
import { useRoutes } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import NavBar from './components/NavBar';
import Footer from './components/Footer';
import Loading from './components/Loading';

const Home = lazy(() => import('./components/Home'));
const PlasmaExchange = lazy(() => import('./components/PlasmaExchange'));
const Soluciones = lazy(() => import('./components/Soluciones'));

function App() {
  const routes = useRoutes([
    { path: '/', element: <Home /> },
    { path: '/soluciones-crrt', element: <Soluciones /> },
    { path: '/pex', element: <PlasmaExchange /> },
  ]);

  return (
    <>
      <NavBar />
      <Container fluid>
        <Suspense fallback={<Loading />}>{routes}</Suspense>
      </Container>
      <Footer />
    </>
  );
}

export default App;
